import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { NotFound } from "./components/pages/404";
import { LineFriend } from "./components/pages/LineFriend";
import { Campaign } from "./components/pages/campaign";
import { CampaignDetail } from "./components/pages/campaign_detail";
import { ChangeEmail } from "./components/pages/change_email";
import { ChangeRegisterdNumber } from "./components/pages/change_registered_number";
import { EditMember } from "./components/pages/edit_member";
import { ForgotPassword } from "./components/pages/forgot_password";
import { LineAuthCallback } from "./components/pages/line_auth_callback";
import { Login } from "./components/pages/login";
import { MailSend } from "./components/pages/mail_send";
import { MemberRegistration } from "./components/pages/member_registration";
import { MemberRegistrationEmail } from "./components/pages/member_registration_email";
import { MenuPage } from "./components/pages/menu";
import { Notification } from "./components/pages/notification";
import { Redirect } from "./components/pages/redirect";
import { ResetPassword } from "./components/pages/reset_password";
import { TermOfService } from "./components/pages/term_of_service";
import { AuthTemplate } from "./components/template/AuthTemplate";
import { ModalContainer, ModalLINEContainer } from "./components/ui";
import { ModalErrorContainer } from "./components/ui/modal/container/modalErrorContainer";
import "./index.css";
import { BaseProvider } from "./providers/base";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

export const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/:tenant_code/login",
    element: (
      <AuthTemplate requireLogout>
        <Login />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/mail_send",
    element: (
      <AuthTemplate>
        <MailSend />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/register",
    element: (
      <AuthTemplate requireLogout>
        <MemberRegistrationEmail />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/register/:step",
    element: (
      <AuthTemplate requireLogout>
        <MemberRegistration />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/forgot_password",
    element: (
      <AuthTemplate requireLogout>
        <ForgotPassword />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/reset_password/:code",
    element: (
      <AuthTemplate requireLogout>
        <ResetPassword />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/terms_of_service",
    element: <TermOfService />,
  },
  {
    path: "/:tenant_code/change_email",
    element: (
      <AuthTemplate requireLogin>
        <ChangeEmail />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/edit_member",
    element: (
      <AuthTemplate requireLogin>
        <EditMember />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code",
    element: (
      <AuthTemplate requireLogout>
        <Campaign />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/campaign",
    element: (
      <AuthTemplate>
        <Campaign />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/campaign?tab=participating",
    element: (
      <AuthTemplate requireLogin>
        <Campaign />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/campaign?tab=earned",
    element: (
      <AuthTemplate requireLogin>
        <Campaign />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/campaign/:campaignId",
    element: <CampaignDetail />,
  },
  {
    path: "/:tenant_code/campaign/:campaignId?tab=preview",
    element: <CampaignDetail />,
  },
  {
    path: "/:tenant_code/campaign/:campaignId?tab=participating",
    element: (
      <AuthTemplate requireLogin>
        <CampaignDetail />,
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/notification/:page",
    element: (
      <AuthTemplate requireLogin>
        <Notification />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/line_auth_callback",
    element: (
      <AuthTemplate requireLogin>
        <LineAuthCallback />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/change_register_number",
    element: (
      <AuthTemplate requireLogin>
        <ChangeRegisterdNumber />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/line_friend",
    element: (
      <AuthTemplate requireLogout>
        <LineFriend />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/menu",
    element: (
      <AuthTemplate>
        <MenuPage />
      </AuthTemplate>
    ),
  },
  {
    path: "/:tenant_code/su-pay/redirect",
    element: <Redirect />,
  },
]);

root.render(
  <React.Fragment>
    <BaseProvider>
      <RouterProvider router={router} />
      <ModalContainer />
      <ModalErrorContainer />
      <ModalLINEContainer />
    </BaseProvider>
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
