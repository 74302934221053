import React from "react";
import { useParams } from "react-router";
import { Clock } from "../../../../common/icons/Clock";
import { LimitLabel } from "../../../../common/icons/LimitLabel";
import { RewardLabel } from "../../../../common/icons/RewardLabel";
import { isAquiredAllReward } from "../../../../common/utils/campaign";
import {
  formatDateTime,
  isWithinThreeDays,
} from "../../../../common/utils/date";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { Campaign } from "../campaign.type";
import { useCampaign } from "../hooks/useCampaign";
import { CampaignCardContainer } from "../style/campaign-card";
import {
  CampaignAquiredText,
  CampaignButtonWrapper,
  CampaignCard,
  CampaignCardContentWrapper,
  CampaignCardImage,
  CampaignCardMile,
  CampaignCardMileText,
  CampaignCardText,
  CampaignDetailLink,
  TextContainer,
} from "../style/campaign-participating-card";
import { MileProgressBarSmall } from "./MileProgressBarSmall";

type Props = {
  campaign: Campaign;
  isPromoted?: boolean;
};

export const CampaignParticipatingCard: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { tenant_code } = useParams();
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;
  const { caluclatedMile, calculateMileRemaining } = useCampaign(
    props.campaign,
    tenant_code,
    isLogin,
  );

  return (
    <CampaignCardContainer isPromoted={props.isPromoted}>
      <CampaignCard
        onClick={() => {
          navigate(`/campaign/${props.campaign.id}?tab=participating`);
        }}
        isPromoted={props.isPromoted}
      >
        <CampaignCardImage
          src={
            props.campaign?.campaign_banner_url ||
            "/image/dummy_banner_image.png"
          }
          alt="sample01"
        />
        <CampaignCardText>
          <TextContainer>
            {isWithinThreeDays(props.campaign.implementation_period_end) && (
              <LimitLabel />
            )}
            <Clock style={{ margin: 5, verticalAlign: "middle" }} />
            {formatDateTime(props.campaign.implementation_period_end)}まで
          </TextContainer>
        </CampaignCardText>
        <CampaignCardContentWrapper
          isAquired={isAquiredAllReward(props.campaign)}
        >
          {props.campaign?.rewards[0]?.is_repetition && (
            <RewardLabel is_card={true} />
          )}
          {!isAquiredAllReward(props.campaign) && (
            <CampaignCardMile>
              <CampaignCardMileText>
                あと
                {calculateMileRemaining(
                  props.campaign?.next_mileage || 0,
                  props.campaign?.accumulated_mileage || 0,
                )}
                マイル
              </CampaignCardMileText>
              で特典GET!
              <MileProgressBarSmall
                value={caluclatedMile(props.campaign)}
                max={props.campaign?.next_mileage || 500}
                min={props.campaign?.before_mileage || 0}
              ></MileProgressBarSmall>
            </CampaignCardMile>
          )}
          <CampaignButtonWrapper>
            {isAquiredAllReward(props.campaign) && (
              <CampaignAquiredText>特典獲得済み</CampaignAquiredText>
            )}
            <CampaignDetailLink
              onClick={() =>
                navigate(`/campaign/${props.campaign.id}?tab=participating`)
              }
              isAlreadyEarned={isAquiredAllReward(props.campaign)}
            >
              詳細
            </CampaignDetailLink>
          </CampaignButtonWrapper>
        </CampaignCardContentWrapper>
      </CampaignCard>
    </CampaignCardContainer>
  );
};
