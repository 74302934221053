import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useScroll } from "react-use";
import { fetchCampaignData } from "../../../../common/utils/campaign";
import { useGetElementProperty } from "../../../../lib/hooks/useGetElementProperty";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { Campaign } from "../campaign.type";
import { FilterButtonGroup } from "../component/ButtonGroup";
import { CampaignCardComponent } from "../component/CampaignCardComponent";
import { CampaignParticipatingCard } from "../component/CampaignParticipatingCard";
import { EncourageRegisterMember } from "../component/EncourageRegisterMember";
import { SCROLL_PAGE_HEIGHT } from "../constant";
import {
  BackGround,
  CampaignCardWrapper,
  CampaignPRTitle,
  CampaignPRTitleWrapper,
  CenteredText,
  Text,
} from "../style/campaign-card";
import { Spinner } from "./../../../../components/ui/spinner/Spinner";
import useCampaignStore, {
  filterCampaigns,
  resetCampaignData,
} from "./../redux/campaign/store";

export const CampaignContainer: React.FC = () => {
  const navigate = useNavigate();
  const { tenant_code } = useParams();
  const filteredCampaigns = useCampaignStore(
    (state) => state.filteredCampaigns,
  );
  const filteredPromotedCampaigns = useCampaignStore(
    (state) => state.filteredPromotedCampaigns,
  );

  const categories = useCampaignStore((state) => state.categories);
  const loading = useCampaignStore((state) => state.loading);
  const [selectedFilterOption, setSelectedFilterOption] = useState("all");
  // フィルターの選択肢
  const filterOptions = ["all"].concat(categories);

  // ページネーション
  const [pagenatedCampaigns, setPagenatedCampaigns] = useState<Campaign[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  // スクロール位置取得
  const targetRef = useRef(null);
  const { y } = useScroll(targetRef);
  const { getElementProperty } =
    useGetElementProperty<HTMLDivElement>(targetRef);

  const handleSelectFilterChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const selectedValue = (e.target as HTMLButtonElement).value;
    setSelectedFilterOption(selectedValue);
    resetCampaignData();
    filterCampaigns(selectedValue);
  };

  const isLogin = localStorage.getItem("t__mm_access_token") !== null;

  const onGoToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    fetchCampaignData(tenant_code ?? "", isLogin);
  }, []);

  useEffect(() => {
    const targetHeight = getElementProperty("height");
    if (y > targetHeight * currentPage - SCROLL_PAGE_HEIGHT) {
      setCurrentPage(currentPage + 1);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = 0;
    const currentItems = filteredCampaigns.slice(
      indexOfFirstItem,
      indexOfLastItem,
    );
    setPagenatedCampaigns(currentItems);
  }, [y, filteredCampaigns]);

  return (
    <>
      <FilterButtonGroup
        options={filterOptions}
        selectedOption={selectedFilterOption}
        onClick={handleSelectFilterChange}
      />
      <BackGround>
        <CampaignCardWrapper ref={targetRef}>
          {!isLogin && <EncourageRegisterMember onClick={onGoToLogin} />}
          {loading && <Spinner />}
          {!loading && filteredPromotedCampaigns.length !== 0 && (
            <CampaignPRTitleWrapper>
              <CampaignPRTitle>PRキャンペーン</CampaignPRTitle>
            </CampaignPRTitleWrapper>
          )}

          {!loading &&
            filteredPromotedCampaigns.length !== 0 &&
            filteredPromotedCampaigns.map((campaign: Campaign) => {
              return campaign.participating ? (
                <CampaignParticipatingCard
                  key={campaign.id}
                  campaign={campaign}
                  isPromoted={true}
                />
              ) : (
                <CampaignCardComponent
                  key={campaign.id}
                  campaign={campaign}
                  isLogin={isLogin}
                />
              );
            })}
          {!loading &&
            pagenatedCampaigns.length !== 0 &&
            pagenatedCampaigns.map((campaign: Campaign) => {
              return campaign.participating ? (
                <CampaignParticipatingCard
                  key={campaign.id}
                  campaign={campaign}
                />
              ) : (
                <CampaignCardComponent
                  key={campaign.id}
                  campaign={campaign}
                  isLogin={isLogin}
                />
              );
            })}
          {!loading &&
            filteredPromotedCampaigns.length === 0 &&
            pagenatedCampaigns.length === 0 && (
              <CenteredText>
                <Text>
                  実施している <br />
                  キャンペーンはありません
                </Text>
              </CenteredText>
            )}
        </CampaignCardWrapper>
      </BackGround>
    </>
  );
};
