import React from "react";
import {
  EncourageRegisterMemberImage,
  EncourageRegisterMemberWrapper,
} from "../style/connect-member";
import { ConnectMemberButton } from "../ui/ConnectMemberButton";

type Props = {
  onClick: () => void;
};

export const EncourageRegisterMember: React.FC<Props> = (props) => {
  return (
    <EncourageRegisterMemberWrapper>
      <EncourageRegisterMemberImage src="/assets/campaign/register_area_image.svg" />
      <ConnectMemberButton text="ログイン・会員登録" onClick={props.onClick} />
    </EncourageRegisterMemberWrapper>
  );
};
